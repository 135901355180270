body {
  color: #2c2c2c;
  font-size: 14px !important;
  font-family: Montserrat,Helvetica Neue,Arial,sans-serif !important;
  overflow-x: hidden;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}
a, a:focus, a:hover {
  color: #f96332;
}
.clear-filter[filter-color=orange] {
  background: rgba(44,44,44,.2);
  background: linear-gradient(0deg,rgba(44,44,44,.2),rgba(224,23,3,.6));
}

.page-header {
  min-height: 100vh;
  max-height: 999px;
  padding: 0;
  color: #fff;
  position: relative;
  overflow: hidden;
}