.navbar a:not(.btn):not(.dropdown-item) {
  color: #fff;
}
.navbar .navbar-brand {
  text-transform: uppercase;
  font-size: .8571em;
  padding-top: .5rem;
  padding-bottom: .5rem;
  line-height: 1.625rem;
}
.navbar a {
  vertical-align: middle;
}
.navbar-brand {
  display: inline-block;
  padding-top: .3125rem;
  padding-bottom: .3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
}
.navbar.navbar-transparent {
  background-color: transparent!important;
  box-shadow: none;
  color: #fff;
  padding-top: 20px!important;
}
.navbar {
  padding-top: .625rem;
  padding-bottom: .625rem;
  min-height: 53px;
  margin-bottom: 20px;
  box-shadow: 0 0 20px 0 rgba(0,0,0,.15);
}
.bg-primary {
  background-color: #f96332 !important;
}


.page-header .page-header-image {
  position: absolute;
  background-size: cover;
  background-position: 50%;
  width: 100%;
  height: 100%;
  z-index: -1;
}
.page-header .container {
  height: 100%;
  z-index: 1;
  text-align: center;
  position: relative;
}

.page-header>.container {
  z-index: 2;
  padding-top: 12vh;
  padding-bottom: 40px;
}
.page-header .container>.content-center {
  top: 37%;
}
.page-header .content-center {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 2;
  transform: translate(-50%,-50%);
  text-align: center;
  color: #fff;
  padding: 0 15px;
  width: 100%;
  max-width: 880px;
}
.page-header .category-absolute {
  position: absolute;
  top: 100vh;
  margin-top: -60px;
  padding: 0 15px;
  width: 100%;
  color: hsla(0,0%,100%,.5);
}
.brand .h1-seo {
  font-size: 2.8em;
  text-transform: uppercase;
  font-weight: 300;
}
.brand .n-logo {
  max-width: 100px;
  margin-bottom: 40px;
}
.category {
  text-transform: capitalize;
  font-weight: 700;
  color: #9a9a9a;
}